<ion-item
  *ngIf="patient"
  [disabled]="disabled"
  button="true"
  detail="false"
  lines="none"
  class="item-row item-line ion-no-padding">
  <lib-avatar
    class="grey-background"
    [url]="patient.avatarUrl"
    [fullName]="patient.getFullName()"></lib-avatar>

  <ion-list class="row-main">
    <ion-item lines="none" class="item-layout">
      <ion-text class="full-name">{{ patient.firstName }} {{ patient.lastName }}</ion-text>
    </ion-item>

    <ion-item lines="none" class="item-layout">
      <ion-text class="label-input gender">
        {{ patient.gender || '(' + ('shared.patients.no-gender-specified' | translate) + ')' }}
      </ion-text>
    </ion-item>

    <ion-item lines="none" class="item-layout item-spacer ion-hide-md-up"></ion-item>

    <ion-item lines="none" class="item-layout ion-hide-md-up">
      <ion-text class="label-input">
        @if (patient.birthDate) {
          {{ patient.birthDate | localeDate }} ({{ patient | ageCount }})
        } @else {
          {{ '(' + ('shared.patients.no-birthdate-specified' | translate) + ')' }}
        }
      </ion-text>
    </ion-item>
  </ion-list>

  <ion-list slot="end" class="row-details ion-hide-md-down">
    <ion-item lines="none" class="item-layout">
      <ion-text class="label-input birthdate">
        {{ patient.birthDate | localeDate }} ({{ patient | ageCount }})
      </ion-text>
    </ion-item>
  </ion-list>
  <fa-icon slot="end" [icon]="ICONS.OPEN"></fa-icon>
</ion-item>
