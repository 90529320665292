import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DocumentFilter } from 'projects/core/src/lib/models/documents.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { SearchParams, SortDirection, SortParams } from 'projects/core/src/lib/models/shared.model';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { ItemSorterService } from 'projects/core/src/lib/services/item-sorter.service';
import { RequiredActionsService } from 'projects/core/src/lib/services/required-actions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-tasks-browser',
  templateUrl: './tasks-browser.component.html',
  styleUrls: ['./tasks-browser.component.scss'],
})
export class TasksBrowserComponent implements OnInit {
  requiredActions: RequiredActionsDetails[] = [];

  autoRefreshSub: Subscription;

  requiredActionsList: RequiredActionsDetails[] = [];
  viewList: RequiredActionsDetails[];

  filtersPreset: DocumentFilter[] = [];
  searchParams = new SearchParams();
  sortParams = new SortParams();

  constructor(
    private itemSearcherService: ItemSearcherService,
    private requiredActionsService: RequiredActionsService,
    private destroyRef: DestroyRef,
  ) {
    this.searchParams.fields = ['title', 'status', 'endDate'];
    this.sortParams.field = 'endDate';
    this.sortParams.direction = SortDirection.ASC;
  }

  get isLoading(): boolean {
    return this.requiredActionsService.firstRequestLoading;
  }

  startRequiredActionsSubscription(): Promise<void> {
    return new Promise((resolve) => {
      this.requiredActionsService.requiredActions$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((requiredActions: RequiredActionsDetails[]) => {
          this.requiredActions = requiredActions;
          this.refreshViewItems();
          resolve();
        });
    });
  }

  async ngOnInit() {
    await this.startRequiredActionsSubscription();
  }

  refreshViewItems(): void {
    this.viewList = [];
    if (!this.isLoading && this.requiredActions) {
      const searchedItems = this.itemSearcherService.search(
        this.requiredActions,
        this.searchParams,
      );
      this.viewList = ItemSorterService.sort(searchedItems, this.sortParams);
    }
  }

  get hasActions(): boolean {
    return !!this.viewList?.length;
  }

  get noResultMessage(): string {
    const hasSearchCriterions = this.searchParams?.criterions?.length > 0;
    if (hasSearchCriterions) {
      return 'shared.tasks-browser.no-tasks-found-for-your-search';
    } else {
      return 'shared.tasks-browser.no-tasks-found';
    }
  }
}
