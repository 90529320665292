<div id="item-row" class="ion-no-padding">
  <div id="patient-info" [grow]="1">
    <div id="avatar">
      <lib-avatar
        *ngIf="patient"
        class="white-background"
        [url]="patient.avatarUrl"
        [fullName]="patient.getFullName()"></lib-avatar>

      <ion-skeleton-text *ngIf="!patient" id="image-skeleton" animated></ion-skeleton-text>
    </div>

    <div id="patient-details">
      <div id="name" *ngIf="patient">
        <ion-text>
          <strong>
            {{ patient.getFullName() }}
          </strong>
        </ion-text>
      </div>

      <ng-container *ngIf="patient">
        <ion-text id="birthdate" class="label-input">
          @if (patient.birthDate) {
            {{ patient.birthDate | localeDate: 'shortDate' }} ({{ patient | ageCount }})
          } @else {
            {{ 'general.not-specified' | translate }}
          }
        </ion-text>
        <span class="ion-hide-md-down">&nbsp;–&nbsp;</span>
        <br class="ion-hide-md-up" />
        <ion-text id="tel-number" class="label-input">
          {{ 'general.phone-number-short' | translate }} :
          {{ patient.phoneNumber || ('general.not-specified' | translate) }}
        </ion-text>
      </ng-container>

      <ng-container *ngIf="!patient">
        <div *ngFor="let skeleton of [0, 0]" class="patient-details-skeleton">
          <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
        </div>

        <div class="patient-details-skeleton ion-hide-md-up">
          <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
        </div>
      </ng-container>
    </div>
  </div>

  <ion-button
    *ngIf="patient"
    (click)="navigateToPatientData()"
    color="tertiary"
    class="forward-button ion-no-margin"
    slot="end">
    <fa-icon slot="end" [icon]="icons.forward"></fa-icon>
    <span class="ion-hide-md-down">{{ 'shared.patients.personal-data' | translate }}</span>
    <span class="ion-hide-md-up">{{ 'shared.patients.data' | translate }}</span>
  </ion-button>

  <div *ngIf="!patient" class="patient-details-skeleton">
    <ion-skeleton-text class="rounded-small like-h1" animated></ion-skeleton-text>
  </div>
</div>
