import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Component({
  selector: 'lib-patient-summary-header',
  templateUrl: './patient-summary-header.component.html',
  styleUrls: ['./patient-summary-header.component.scss'],
})
export class PatientSummaryHeaderComponent {
  @Input() patient: Patient;

  readonly icons = {
    forward: faChevronRight,
  };

  constructor(private router: Router) {}

  navigateToPatientData(): void {
    this.router.navigate(['portal/patients/', this.patient.patientID, 'patient-data']);
  }
}
