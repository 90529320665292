<ion-segment
  *ngIf="laboratoryResults?.groups?.length > 0"
  [(ngModel)]="selectedGroup"
  class="ion-hide-md-down">
  @for (group of laboratoryResults.groups; track group.name; let i = $index) {
    <ion-segment-button [value]="i">
      <ion-label class="p4">{{ group.name }}</ion-label>
    </ion-segment-button>
  }
</ion-segment>

<div class="menu-button ion-hide-md-up" *ngIf="laboratoryResults?.groups?.length > 0">
  <ion-button
    id="popover-trigger-button"
    class="ion-no-margin"
    color="primary"
    (click)="isMenuPopupOpen = true">
    <fa-icon slot="end" icon="bars"></fa-icon>
    <span>{{ laboratoryResults.groups[selectedGroup].name }}</span>
  </ion-button>

  <ion-popover
    [isOpen]="isMenuPopupOpen"
    (ionPopoverWillDismiss)="isMenuPopupOpen = false"
    [dismissOnSelect]="true"
    trigger="popover-trigger-button"
    alignment="end"
    animated="true"
    arrow="false"
    side="bottom"
    cssClass="popover">
    <ng-template>
      <ion-list class="popover-item-list">
        @for (group of laboratoryResults.groups; track group.name; let i = $index) {
          <ion-item (click)="selectedGroup = i" class="p" lines="none">
            <ion-label>{{ group.name }}</ion-label>
          </ion-item>
        }
      </ion-list>
    </ng-template>
  </ion-popover>
</div>
<div class="table-container">
  @for (group of laboratoryResults?.groups; track group.name; let i = $index) {
    <lib-laboratory-results-table
      [hidden]="i !== selectedGroup"
      [tableData]="group.tableData"></lib-laboratory-results-table>
  }
</div>
