import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AboutService } from 'projects/core/src/lib/services/about.service';

@Directive({
  selector: '[demoOnly]',
})
export class DemoOnlyDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private aboutService: AboutService,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.aboutService.isDemo()
      ? this.viewContainer.createEmbeddedView(this.templateRef)
      : this.viewContainer.clear();
  }
}
