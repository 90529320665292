import { Component, DestroyRef, OnInit, Output } from '@angular/core';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { LoadingService } from './../../../../../core/src/lib/services/loading.service';

import { ActivatedRoute } from '@angular/router';
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IheDocumentsResourceMapper } from 'projects/core/src/lib/mappers/ihe-document.mapper';
import {
  EventMessage,
  EventMessageType,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { IheDocumentDetails } from 'projects/core/src/lib/models/ihe-documents.model';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { IheDocumentsService } from 'projects/core/src/lib/services/ihe-documents.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { ProfileSettingsService } from 'projects/core/src/lib/services/profile-settings.service';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-ihe-documents-browser',
  templateUrl: './ihe-documents-browser.component.html',
  styleUrls: ['./ihe-documents-browser.component.scss'],
})
export class IheDocumentsBrowserComponent implements OnInit {
  @Output()
  viewPatient: Patient;

  loading = true;

  searchParams = new SearchParams();

  readonly expandedViewThreshold: number = 569;

  data: TableConfiguration = {
    table: new TableList(IheDocumentsResourceMapper.getTmpHeaders(), []),
    searchParams: this.searchParams,
    skeletonRowSet: new SkeletonRowSet({ mobile: 330, desktop: 320 }, undefined, faDownload),
    expandedViewThreshold: this.expandedViewThreshold,
    columnWidth: 200,
  };
  documentsList: IheDocumentDetails[] = [];

  readonly icons = { share: faShare };

  constructor(
    private clientConfigService: ClientConfigService,
    private iheDocumentsService: IheDocumentsService,
    private patientService: PatientService,
    private tableDataService: TableDataService,
    private route: ActivatedRoute,
    private profileSettings: ProfileSettingsService,
    private destroyRef: DestroyRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private documentsMapper: IheDocumentsResourceMapper,
    private loadingService: LoadingService,
  ) {
    this.searchParams.fields = ['columns'];
  }

  async ionViewDidEnter() {
    await this.getCurrentPatient();
  }

  async ngOnInit() {
    await this.getCurrentPatient();
  }

  private async getCurrentPatient() {
    const patientID = this.route.snapshot?.paramMap?.get('patientID');
    this.profileSettings.switchPatient(patientID);
    await firstValueFrom(this.patientService.switchAndCacheCurrentPatient(patientID));
    this.viewPatient = await firstValueFrom(this.patientService.getPatient(patientID));
    await this.getIHEDocumentsList();
  }

  private async getIHEDocumentsList() {
    if (!this.viewPatient) {
      return;
    }
    this.loading = true;
    const pid = this.viewPatient.pid;
    const sourceSystem = this.viewPatient.sourceSystemOid;
    this.documentsList = await this.iheDocumentsService.getIheDocumentsList(pid, sourceSystem);
    this.data.table = await this.documentsMapper.mapIheDocumentTable([...this.documentsList]);
    await firstValueFrom(
      this.tableDataService.fetchHeaderPreferencesAndUpdateTable(this.data.table),
    );
    this.loading = false;
  }

  createFileUrl(data): string {
    const missingUrlComponents = [];
    const viewBaseUrl =
      this.clientConfigService.get().activeModules?.iheViewer?.iheConnectorApiBasePath;
    if (!viewBaseUrl) {
      missingUrlComponents.push('viewBaseUrl');
    }

    const resourceId = data;
    const document = this.documentsList.find(
      (documentDetails) => documentDetails.id === resourceId,
    );
    const resource: any = document.originalResource;

    const pid = this.viewPatient.pid;
    if (!pid) {
      missingUrlComponents.push('pid');
    }

    const sourceSystem = this.viewPatient.sourceSystemOid;
    if (!sourceSystem) {
      missingUrlComponents.push('sourceSystem');
    }

    const content0AttachmentUrl = resource.content?.[0]?.attachment?.url;
    if (!content0AttachmentUrl) {
      missingUrlComponents.push('content0AttachmentUrl');
    }

    const extension1ValueString = resource.extension?.find((el) =>
      el.url?.includes('RepositoryUniqueId'),
    )?.valueString;
    if (!extension1ValueString) {
      missingUrlComponents.push('extension1ValueString');
    }

    const masterIdentifierSystem = resource.masterIdentifier.system;
    if (!masterIdentifierSystem) {
      missingUrlComponents.push('masterIdentifierSystem');
    }

    if (missingUrlComponents.length > 0) {
      console.error('The following url components are missing:', missingUrlComponents.join(', '));
      this.toast('shared.ihe-viewer.error-while-generating-file-url');
      return null;
    }
    return `${viewBaseUrl}/view/documents/${pid}/${sourceSystem}/${content0AttachmentUrl}/${extension1ValueString}/${masterIdentifierSystem}`;
  }

  openFileInNewTab(data): void {
    const url = this.createFileUrl(data);
    if (url !== null) {
      window.open(url, '_blank');
    }
  }

  async transferDocuments() {
    const bodyIsAListOfDocumentUniqueIds = (this.data.table.sortedRows || [])
      .filter((row) => row.selected)
      .map((row) => row.id);
    if (bodyIsAListOfDocumentUniqueIds.length === 0) {
      await this.toast('shared.ihe-viewer.please-select-at-least-one-document-for-transfer');
      return;
    }
    try {
      const pid = this.viewPatient.pid;
      const sourceSystem = this.viewPatient.sourceSystemOid;
      await this.iheDocumentsService.transferDocuments(
        pid,
        sourceSystem,
        bodyIsAListOfDocumentUniqueIds,
      );
    } catch (error) {
      console.error('Error while transferring documents', error);
    }
  }

  public async toast(key: string): Promise<void> {
    const toastMessage = await firstValueFrom(this.translateService.get(key));
    this.loadingService.toast(toastMessage);
  }

  resolveEventMessage({ type, data }: EventMessage): void {
    switch (type) {
      case EventMessageType.CLICK:
        this.openFileInNewTab(data);
        break;
    }
  }
}
