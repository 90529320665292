import { Component, Input } from '@angular/core';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Component({
  selector: 'lib-patient-summary-browser',
  templateUrl: './patient-summary-browser.component.html',
  styleUrls: ['./patient-summary-browser.component.scss'],
})
export class PatientSummaryBrowserComponent {
  @Input() patient: Patient;
}
