import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faCalendar,
  faFile,
  faFlaskVial,
  faFolderTree,
  faHandHoldingMedical,
  faHandshake,
  faNotesMedical,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import {
  SetShowTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { AppointmentsService } from 'projects/core/src/lib/services/appointments.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OrdersService } from 'projects/core/src/lib/services/orders.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { TreatmentsService } from 'projects/core/src/lib/services/treatment.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';
import { firstValueFrom } from 'rxjs';
import { MenuItemKeys, SectionViewMenu } from './patient-summary-list.model';
import { LaboratoryResultsService } from 'projects/core/src/lib/services/laboratory-results.service';

@Component({
  selector: 'lib-patient-summary-list',
  templateUrl: './patient-summary-list.component.html',
  styleUrls: ['./patient-summary-list.component.scss'],
})
export class PatientSummaryListComponent implements OnInit {
  readonly rowHeight: number = 70;

  patientID: string;
  isLoading = true;
  menuItems: SectionViewMenu[];

  constructor(
    private router: Router,
    private patientService: PatientService,
    private documentsService: DocumentsService,
    private treatmentsService: TreatmentsService,
    private appointmentsService: AppointmentsService,
    private ordersService: OrdersService,
    private clientService: ClientConfigService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private popupService: PopupService,
    private laboratoryDataService: LaboratoryResultsService,
    public virtualScrollService: VirtualScrollService,
  ) {}

  async ngOnInit() {
    await this.setCurrentPatientId();
    await this.setMenuItems();
  }

  private async setCurrentPatientId(): Promise<void> {
    this.patientID = await this.patientService.getCurrentPatientId();
  }

  private async getMenuItemTranslations(): Promise<any> {
    return await firstValueFrom(
      this.translateService.get('shared.patient-summary-list.menu-item-labels'),
    );
  }

  private async setMenuItems(): Promise<void> {
    const translations: any = await this.getMenuItemTranslations();
    const menu: SectionViewMenu[] = [];

    if (await this.appointmentsService.hasAccessToPatientsAppointments()) {
      menu.push({
        details: await this.getAppointmentMenuDetails(),
        icon: faCalendar,
        label: translations[MenuItemKeys.appointments],
        value: MenuItemKeys.appointments,
      });
    }

    if (this.checkActiveModule(MenuItemKeys.treatments)) {
      menu.push({
        details: await this.getTreatmentMenuDetails(),
        icon: faStethoscope,
        label: translations[MenuItemKeys.treatments],
        value: MenuItemKeys.treatments,
      });
    }

    if (this.checkActiveModule(MenuItemKeys.documents)) {
      menu.push({
        details: await this.getDocumentMenuDetails(),
        icon: faFile,
        label: translations[MenuItemKeys.documents],
        value: MenuItemKeys.documents,
      });
    }

    if (this.checkActiveModule(MenuItemKeys.orders)) {
      menu.push({
        details: await this.getOrderMenuDetails(),
        icon: faNotesMedical,
        label: translations[MenuItemKeys.orders],
        value: MenuItemKeys.orders,
      });
    }

    if (await this.laboratoryDataService.hasAccessToPatientsLaboratoryResults()) {
      menu.push({
        details: '',
        icon: faFlaskVial,
        label: translations[MenuItemKeys.laboratory],
        value: MenuItemKeys.laboratory,
      });
    }

    if (this.checkActiveModule(MenuItemKeys.aftercare)) {
      menu.push({
        details: '',
        icon: faHandHoldingMedical,
        label: translations[MenuItemKeys.aftercare],
        value: MenuItemKeys.aftercare,
      });
    }

    if (this.checkActiveModule(MenuItemKeys.iheViewer)) {
      menu.push({
        details: '',
        icon: faFolderTree,
        label: translations[MenuItemKeys.iheViewer],
        value: MenuItemKeys.iheViewer,
      });
    }

    if (await this.hasSetShowPermission()) {
      menu.push({
        details: '',
        icon: faHandshake,
        label: translations[MenuItemKeys.setShow],
        value: MenuItemKeys.setShow,
      });
    }

    this.menuItems = menu;
    this.isLoading = false;
  }

  private async getTreatmentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.treatments-count', {
        count: await this.treatmentsService.getTreatmentCount(),
      }),
    );
  }

  private async getDocumentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.documents-count', {
        count: await this.documentsService.getDocumentCount(),
      }),
    );
  }

  private async getOrderMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.orders-count', {
        count: await this.ordersService.getOrderCountOfSpecificPatient(),
      }),
    );
  }

  private async getAppointmentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.past-and-upcoming-appointments', {
        upcomingCount: await this.appointmentsService.getUpcomingAppointmentCount(),
        pastCount: await this.appointmentsService.getPastAppointmentCount(),
      }),
    );
  }

  private checkActiveModule(key: string): boolean {
    return this.clientService.get().activeModules[key];
  }

  private async hasSetShowPermission(): Promise<boolean> {
    return await firstValueFrom(this.patientService.checkSetShow(this.patientID));
  }

  getSkeletonItems(): number[] {
    return Object.values(MenuItemKeys)
      .filter((key) => this.checkActiveModule(key))
      .map((_, i) => i);
  }

  async navigateToMenuItem(menuItemKey: MenuItemKeys): Promise<void> {
    if (menuItemKey === MenuItemKeys.setShow) {
      await this.openSetShow();
    } else {
      await this.router.navigate(['/portal/patients', this.patientID, menuItemKey]);
    }
  }

  private async openSetShow(): Promise<void> {
    await this.loadingService.load();
    await firstValueFrom(this.patientService.getSetShowForm(this.patientID)).then(
      (form: DynamicForm) => {
        return this.popupService.showDynamicFormModal(
          new DynamicFormConfiguration({
            dynamicForm: form,
            type: DynamicFormType.MULTICHOSEN,
            translationOptions: this.translationOptionsForAccessGranting,
          }),
        );
      },
    );
  }

  private get translationOptionsForAccessGranting(): TranslationOptions {
    return {
      keys: SetShowTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
  }
}
