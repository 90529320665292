import { Component } from '@angular/core';
import { ElementBreakpoint } from 'projects/core/src/lib/services/breakpoint.service';
import { Skeleton } from '../helper/skeleton/skeleton.component';

@Component({
  selector: 'lib-tasks-browser-skeleton',
  template: `
    <lib-auto-layout
      direction="vertical"
      horizontal="stretch"
      [grow]="1"
      class="skeleton-container"
      (onDimensionChange)="onResize($event)">
      @for (row of rows; track row) {
        <lib-auto-layout
          direction="horizontal"
          vertical="center"
          [gap]="[20]"
          class="skeleton-row"
          [class.compact]="isCompact">
          @if (!isCompact) {
            <ion-skeleton-text
              class="like-p shape-skeleton-text time-slot"
              animated></ion-skeleton-text>
          }
          <lib-auto-layout direction="vertical" vertical="stretch" [grow]="1">
            <ion-skeleton-text class="like-p shape-skeleton-text" animated></ion-skeleton-text>
            <ion-skeleton-text class="like-p shape-skeleton-text" animated></ion-skeleton-text>
            @if (isCompact) {
              <ion-skeleton-text
                class="like-p shape-skeleton-text time-slot"
                animated></ion-skeleton-text>
            }
          </lib-auto-layout>
        </lib-auto-layout>
      }
    </lib-auto-layout>
  `,
  styles: `
    :host {
      max-height: 100%;
    }
    .skeleton-container {
      height: 100%;
      max-height: 100%;
    }
    .skeleton-row {
      height: 64px;
      padding: 10px 120px 10px 20px;
      &.compact {
        height: 111px;
        padding: 10px 20px 10px 20px;
      }
      &:nth-child(odd) {
        background-color: var(--color-greyish-3);
      }
    }
    .time-slot {
      width: 135px;
      max-width: 100%;
    }
  `,
})
export class TasksBrowserComponentSkeleton extends Skeleton {
  constructor() {
    const skeletonConfiguration = {
      elementBreakpoint: ElementBreakpoint.MOBILE,
      rowHeightDefinition: {
        condensed: 111,
        extended: 64,
      },
    };
    super(skeletonConfiguration);
  }
}
