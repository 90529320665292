<lib-generic-table-container
  class="stretch-container fill-height"
  [data]="data"
  [isDataLoading]="loading"
  [isRowDataLoading]="loading"
  (eventMessage)="resolveEventMessage($event)">
  <lib-auto-layout actionButtonCollection class="toolbar-item card-layout" [gap]="[1]">
    <ion-button
      color="primary"
      data-api-id="order-type-list"
      class="ion-no-margin"
      (click)="transferDocuments()"
      [disabled]="true">
      <fa-icon slot="end" [icon]="icons.share"></fa-icon>
      <span class="ion-hide-md-down">{{ 'shared.ihe-viewer.transfer-documents' | translate }}</span>
      <span class="ion-hide-md-up">{{
        'shared.ihe-viewer.transfer-documents-short' | translate
      }}</span>
    </ion-button>
  </lib-auto-layout>
</lib-generic-table-container>
