import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfiniteScrollCustomEvent, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { LastVisitedPatientsService } from 'projects/core/src/lib/services/last-visited-patients.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss'],
})
export class PatientSearchComponent implements OnInit {
  allPatients: Patient[] = [];
  lastVisitedPatients: Patient[] = [];
  filteredPatients: Patient[] = [];

  isInitializing: boolean = true;
  isLoading: boolean = true;

  showPatientCreateButton: boolean = false;
  displayedPatientsCount: number;

  readonly searchParams: SearchParams = new SearchParams();
  readonly patientsLoadIncrement: number = 30;

  constructor(
    private itemSearcherService: ItemSearcherService,
    private patientService: PatientService,
    private router: Router,
    private popupService: PopupService,
    private visitedPatientService: LastVisitedPatientsService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private alertService: AlertService,
  ) {
    this.searchParams.fields = [
      'pid',
      'firstName',
      'lastName',
      'birthDate',
      'sex',
      'currentVisit',
      'street',
      'zipCode',
      'domicile',
      'phoneNumber',
      'email',
    ];
  }

  get maxSkeletonAmountAsArray(): number[] {
    return SkeletonService.getRowAmountAsArray(262, 68);
  }

  async ngOnInit(): Promise<void> {
    this.displayedPatientsCount = this.patientsLoadIncrement;
    await this.initializePatientListData();
  }

  async createPatient(): Promise<void> {
    const modalResult: OverlayEventDetail = await this.popupService.showDynamicFormModal(
      this.patientService.patientCreateFormConfiguration,
    );
    if (modalResult.role === OverlayEventRole.save && modalResult.data) {
      const patientId: string = PatientService.retrieveIdOfCreatedPatient(
        modalResult.data.response,
      );
      await this.navigateToCreatedPatient(patientId);
    }
  }

  async openPatient(chosenPatient: Patient): Promise<void> {
    await this.showTranslatedPatientLoadingMessage();
    await this.saveVisitedPatientIdAndNavigate(chosenPatient);
  }

  getPatientResults(): void {
    if (this.searchParams.criterions && !this.isInitializing) {
      this.filteredPatients = this.itemSearcherService.search(this.allPatients, this.searchParams);
    } else {
      this.filteredPatients = this.allPatients.slice(0, this.displayedPatientsCount);
    }
    this.isLoading = false;
  }

  showLoading(): void {
    this.isLoading = true;
  }

  async displayMorePatients(event: InfiniteScrollCustomEvent): Promise<void> {
    this.displayedPatientsCount += this.patientsLoadIncrement;
    this.filteredPatients = this.allPatients.slice(0, this.displayedPatientsCount);
    await event.target.complete();
  }

  private async initializePatientListData(): Promise<void> {
    this.isInitializing = true;
    await this.loadAndSetPatientListData();
    this.showPatientCreateButton = await this.patientService.shouldShowCreatePatientButton();
    this.isInitializing = false;
  }

  private async loadAndSetPatientListData(): Promise<void> {
    this.allPatients = await firstValueFrom(this.patientService.getAllPatients());
    this.filteredPatients = this.allPatients.slice(0, this.displayedPatientsCount);
    this.lastVisitedPatients = await this.patientService.retrieveLastVisitedPatients(
      this.allPatients,
    );
  }

  private async showTranslatedPatientLoadingMessage(): Promise<void> {
    const loadingMessage: string = await firstValueFrom(
      this.translateService.get('shared.patients.opening-patient'),
    );
    await this.loadingService.load(loadingMessage);
  }

  private async navigateToCreatedPatient(patientsId: string): Promise<void> {
    try {
      await this.showTranslatedPatientLoadingMessage();
      const patient: Patient = await this.patientService.retrieveRecentlyCreatedPatient(patientsId);
      await this.saveVisitedPatientIdAndNavigate(patient);
    } catch (error) {
      await this.loadingService.stop();
      await this.alertService.presentAlert(AlertMessagesData.httpRequestError);
      await this.modalController.dismiss(error, 'error');
    }
  }

  private async saveVisitedPatientIdAndNavigate(patient: Patient): Promise<void> {
    await firstValueFrom(
      this.visitedPatientService.updateLastVisitedPatientIdList(patient.patientID),
    );
    await this.navigateToPatient(patient);
    await this.loadingService.stop();
    await this.resetPatientListData();
  }

  private async resetPatientListData(): Promise<void> {
    this.displayedPatientsCount = this.patientsLoadIncrement;
    this.searchParams.criterions = null;
    await this.loadAndSetPatientListData();
  }

  private async navigateToPatient(patient: Patient): Promise<void> {
    await this.router.navigate([this.router.url, patient.patientID.toString()], {
      state: { patient },
    });
  }
}
