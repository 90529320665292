import { Component, Input } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'lib-section-divider',
  templateUrl: './section-divider.component.html',
  styleUrl: './section-divider.component.scss',
})
export class SectionDividerComponent {
  @Input({ required: true }) title: string;
  @Input() icon: IconName;
  @Input() color: SectionDividerColor = 'color-grey-light';
}

type SectionDividerColor = 'color-primary' | 'color-grey-light';
