import { Component, Input } from '@angular/core';
import { LaboratoryResultTable } from 'projects/core/src/lib/models/laboratory-results.model';

@Component({
  selector: 'lib-laboratory-results-table',
  templateUrl: './laboratory-results-table.component.html',
  styleUrl: './laboratory-results-table.component.scss',
})
export class LaboratoryResultsTableComponent {
  @Input() tableData!: LaboratoryResultTable;
  escapeNewLine(value: string) {
    return value.replace(/\\n/g, '\n');
  }
}
