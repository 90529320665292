import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() url: string;
  @Input() fullName: string;

  hasError = false;

  getInitials(): string {
    const names = this.fullName.split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : '';

    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
}
