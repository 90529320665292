<ion-grid class="content ion-hide-lg-down" id="button-grid-desktop" [fixed]="true">
  <ion-row>
    <ion-col sizeXl="8" size="12">
      <div class="card-transparent">
        <div id="button-section">
          <lib-patient-name-label [patient]="patient"></lib-patient-name-label>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content>
  <ion-grid class="content fill-height stretch-container" [fixed]="true">
    <ion-row class="stretch-flex stretch-container">
      <ion-col class="stretch-flex-force stretch-container" sizeXl="8" size="12">
        <div class="stretch-flex stretch-container card-transparent">
          <div id="button-section" class="ion-hide-xl-up">
            <lib-patient-name-label
              [patient]="patient"
              class="block-button"></lib-patient-name-label>
          </div>
          <div class="gap-header ion-hide-lg-up"></div>

          <ng-content></ng-content>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
