import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PortalGuard } from 'projects/core/src/lib/guards/portal.guard';
import { TermsOfServicePageGuard } from 'projects/core/src/lib/guards/terms-of-service-page.guard.service';
import { LandingpageModuleGuard } from 'projects/core/src/lib/guards/modules/landingpage-module.guard';
import { SessionTimeoutActiveGuard } from 'projects/core/src/lib/guards/session-timeout-active.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingPageModule),
    canActivate: [LandingpageModuleGuard],
  },
  {
    path: 'portal',
    loadChildren: () => import('./pages/portal/portal.module').then((m) => m.PortalPageModule),
    canActivate: [PortalGuard],
  },
  {
    path: 'imprint',
    loadChildren: () => import('./pages/imprint/imprint.module').then((m) => m.ImprintPageModule),
    canActivate: [LandingpageModuleGuard],
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyPageModule),
    canActivate: [LandingpageModuleGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('./pages/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule,
      ),
    canActivate: [TermsOfServicePageGuard],
  },
  {
    path: 'session-timeout',
    loadChildren: () =>
      import('./pages/session-timeout/session-timeout.module').then(
        (m) => m.SessionTimeoutPageModule,
      ),
    canActivate: [SessionTimeoutActiveGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
