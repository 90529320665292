import { IconDefinition } from '@fortawesome/angular-fontawesome/types';

export enum MenuItemKeys {
  appointments = 'appointments',
  treatments = 'treatments',
  documents = 'documents',
  laboratory = 'laboratory',
  orders = 'orders',
  aftercare = 'aftercare',
  iheViewer = 'iheViewer',
  setShow = 'setShow',
}

export interface SectionViewMenu {
  value: MenuItemKeys;
  icon: IconDefinition;
  label: string;
  details: string;
}
