import { Component, Input } from '@angular/core';
import { LaboratoryResults } from 'projects/core/src/lib/models/laboratory-results.model';

@Component({
  selector: 'lib-laboratory-results',
  templateUrl: './laboratory-results.component.html',
  styleUrl: './laboratory-results.component.scss',
})
export class LaboratoryResultsComponent {
  @Input() laboratoryResults: LaboratoryResults;
  selectedGroup: number = 0;
  isMenuPopupOpen: boolean = false;
}
