<lib-generic-table-container
  class="stretch-container fill-height"
  [data]="data"
  [isDataLoading]="isDataLoading"
  [isRowDataLoading]="isRowDataLoading"
  (eventMessage)="resolveEventMessage($event)">
  @if (isCreateTreatmentVisible) {
    <lib-auto-layout actionButtonCollection class="toolbar-item card-layout" [gap]="[1]">
      <ion-button
        class="p3 treatment-creation ion-no-margin"
        color="primary"
        (click)="createTreatmentClicked.emit()"
        data-api-id="treatment-creation">
        <fa-icon size="xs" slot="start" icon="plus"></fa-icon>
        {{ 'general.create' | translate }}
      </ion-button>
    </lib-auto-layout>
  }
</lib-generic-table-container>
