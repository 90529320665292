import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Component({
  selector: 'lib-patient-name-label',
  templateUrl: './patient-name-label.component.html',
  styleUrls: ['./patient-name-label.component.scss'],
})
export class PatientNameLabelComponent {
  @Input() patient: Patient;

  constructor(private router: Router) {}

  navigateToPatientView(): void {
    this.router.navigate(['portal/patients/', this.patient.patientID]);
  }
}
