<lib-auto-layout [grow]="1" direction="vertical" horizontal="stretch">
  <ion-item-divider [ngClass]="color" class="ion-align-items-center">
    @if (icon) {
      <fa-icon [icon]="icon" size="sm"></fa-icon>
    }

    <p class="no-margin">{{ title }}</p>
  </ion-item-divider>

  <ng-content select="[section]"></ng-content>
</lib-auto-layout>
