@if (tableData) {
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          @for (column of tableData.fixedColumns; track column.name) {
            <th class="fixed p">
              <strong>{{ escapeNewLine(column.name) }}</strong>
            </th>
          }
          @for (column of tableData.columns; track column.name) {
            <th class="p">
              <strong>{{ escapeNewLine(column.name) }}</strong>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of tableData.rows; let i = $index; track i) {
          <tr>
            @for (cell of row.fixedCells; let j = $index; track j) {
              <td class="fixed p">{{ cell.value }}</td>
            }
            @for (cell of row.cells; let j = $index; track j) {
              <td class="p">{{ cell.value }}</td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
}
