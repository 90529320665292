<ion-list class="card-list fill-height">
  <cdk-virtual-scroll-viewport
    class="scrollbar stretch-self"
    [itemSize]="rowHeight"
    [minBufferPx]="virtualScrollService.getBufferPx('min')"
    [maxBufferPx]="virtualScrollService.getBufferPx('max')">
    <ng-container *ngIf="!isLoading && menuItems">
      <ion-item
        button
        *ngFor="let item of menuItems"
        (click)="navigateToMenuItem(item.value)"
        lines="none">
        <div class="item-container">
          <div class="icon-container">
            <fa-icon slot="start" [icon]="item.icon" class="greyish" size="xl"></fa-icon>
          </div>

          <div class="item-details">
            <h3>
              <strong>{{ item.label }}</strong>
            </h3>
            <ion-text *ngIf="item.details">{{ item.details }}</ion-text>
          </div>
        </div>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <ion-item *ngFor="let item of getSkeletonItems()" lines="none">
        <div class="item-container">
          <div class="icon-skeleton">
            <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
          </div>

          <div class="item-details">
            <div *ngFor="let skeleton of [0, 0]" class="item-details-skeleton">
              <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
            </div>
          </div>
        </div>
      </ion-item>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ion-list>
