import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EventMessage,
  EventMessageType,
  SkeletonHeaderOffset,
  SkeletonRowSet,
  TableConfiguration,
  TableList,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { SearchParams } from 'projects/core/src/lib/models/shared.model';

@Component({
  selector: 'lib-treatments-table',
  templateUrl: './treatments-table.component.html',
  styleUrl: './treatments-table.component.scss',
})
export class TreatmentsTableComponent {
  @Input() set treatmentList(treatments: TableList) {
    if (treatments) {
      this.treatments = treatments;
    }
  }
  @Input() isCreateTreatmentVisible: boolean;
  @Input() isDataLoading: boolean = true;

  @Output() treatmentClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() createTreatmentClicked: EventEmitter<void> = new EventEmitter<void>();

  isRowDataLoading: boolean = false;

  private readonly headerOffset: SkeletonHeaderOffset = { mobile: 360, desktop: 360 };
  private readonly expandedViewThreshold: number = 569;
  private treatments: TableList;
  private skeletonRowSet: SkeletonRowSet;
  private searchParams = new SearchParams();

  constructor() {
    this.searchParams.fields = ['columns'];
    this.skeletonRowSet = new SkeletonRowSet(this.headerOffset);
  }

  get data(): TableConfiguration {
    return {
      table: this.treatments,
      searchParams: this.searchParams,
      skeletonRowSet: this.skeletonRowSet,
      expandedViewThreshold: this.expandedViewThreshold,
      columnWidth: 200,
    };
  }

  resolveEventMessage(eventMessage: EventMessage): void {
    switch (eventMessage.type) {
      case EventMessageType.CLICK:
        this.treatmentClicked.emit(eventMessage.data);
        break;
      case EventMessageType.RESORTING:
        this.setResortingLoadingValue(eventMessage.data);
        break;
    }
  }

  private setResortingLoadingValue(value: boolean): void {
    this.isRowDataLoading = value;
  }
}
