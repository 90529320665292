import { ElementBreakpoint } from 'projects/core/src/lib/services/breakpoint.service';
import { Dimensions } from 'projects/theme/src/lib/directives/on-dimension-change.directive';

export class Skeleton implements SkeletonConfiguration {
  rows: number[] = [];
  isCompact = false;
  elementBreakpoint: ElementBreakpoint = ElementBreakpoint.MOBILE_LANDSCAPE;
  rowHeightDefinition?: ResponsiveNumberValue;

  constructor(configuration?: SkeletonConfiguration) {
    if (configuration) {
      this.elementBreakpoint = configuration.elementBreakpoint;
      this.rowHeightDefinition = configuration.rowHeightDefinition;
    }
  }

  get rowHeight(): number {
    return this.isCompact ? this.rowHeightDefinition.condensed : this.rowHeightDefinition.extended;
  }

  onResize(dimension: Dimensions): void {
    this.setViewMode(dimension);
    this.setMaxRowAmountOfCard(dimension);
  }

  setViewMode(dimension: Dimensions): void {
    this.isCompact = dimension.width <= this.elementBreakpoint;
  }

  setMaxRowAmountOfCard(dimension: Dimensions): void {
    this.rows = [];
    const maxRowAmmount = Math.floor(dimension.height / this.rowHeight);
    this.rows = Array.from({ length: maxRowAmmount }, (_, i) => i);
  }
}

export interface SkeletonConfiguration {
  elementBreakpoint?: ElementBreakpoint;
  rowHeightDefinition?: ResponsiveNumberValue;
}

export class ResponsiveNumberValue {
  condensed: number;
  extended: number;
}
