import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Component({
  selector: 'lib-patient-result-row',
  templateUrl: './patient-result-row.component.html',
  styleUrls: ['./patient-result-row.component.scss'],
})
export class PatientResultRowComponent {
  @Input() patient: Patient;
  @Input() disabled: boolean;

  readonly ICONS = {
    OPEN: faChevronRight,
  };
}
